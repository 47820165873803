<template>
    
    <b-container>
     <b-card class="mt-5 rounded shadow-lg">
          <span class="text-dark font-weight-bold">Personal Profile</span> <br>
          <hr>
          <p class="font-italic">
            I am a person who believes in the power of teamwork, in growing and learning more every day, competitive and who does not give up on him ambitions.
            </p>
          
     </b-card>
      <b-card class="mt-1 rounded shadow-sm">
          <span class="text-dark font-weight-bold">Personal</span> <br>
          <hr>
         <b-badge variant="dark">
             Kevin Bohorquez rojas
         </b-badge>
         <br>
         <b-badge>
            https://github.com/scyth3-c             
         </b-badge>
         <br>
         <b-badge variant="dark">
            B1 in English
         </b-badge>
         <br>   
         <b-badge>
             bohordo.png@gmail.com
         </b-badge>
     </b-card>
 </b-container>
  

</template>

<script>
export default {
    name: 'profileVue'
}
</script>